.EventType-SimpleListView {
    display: flex;
    align-items: center;
}

.EventType-ListColorView {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
    margin-left: 16px;
}
