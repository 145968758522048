.EventType-ColorChoice {
    display: flex;
    align-items: center;
}

.EventType-ColorChoiceView {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
}

.EventType-ColorChoiceTitle {
    margin-left: 16px;
}
