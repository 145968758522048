.SuccessSaveModal-Body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    max-height: 400px;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    outline: none;
    padding: 32px;
}

@media screen and (max-width: 768px) {
    .SuccessSaveModal-Body {
        transform: none;
        top: 0;
        left: 0;
        inset: 32px;
        height: auto;
        max-height: unset;
        min-width: unset;
    }
}

.SuccessSaveModal-Close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.SuccessSaveModal-Content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.SuccessSaveModal-ContentImage {
    width: 100%;
    max-width: 300px;
}

.SuccessSaveModal-ContentImage img {
    width: 100%;
}
